export const webDesign = [
  {
    id: "1",
    url: require(`../assets/Easy Deal.png`),
    alt: "Dashboard",
  },
  {
    id: "3",
    url: require(`../assets/CS Lab.png`),
    alt: "Hotel App",
  },
  {
    id: "5",
    url: require(`../assets/maintainance.jpg`),
    alt: "Maintainance",
  },
];
